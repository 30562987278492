import { render, staticRenderFns } from "./SeoTemplateModal.vue?vue&type=template&id=aef838a2&scoped=true"
import script from "./SeoTemplateModal.vue?vue&type=script&lang=js"
export * from "./SeoTemplateModal.vue?vue&type=script&lang=js"
import style0 from "./SeoTemplateModal.vue?vue&type=style&index=0&id=aef838a2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef838a2",
  null
  
)

export default component.exports