var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.config)?_c('div',{staticClass:"settings-seo"},[_c('h2',[_vm._v("SEO")]),_c('DividerComponent'),(_vm.config.main_default_seo_template_id)?_c('SelectDropdown',{attrs:{"options":_vm.config.templates,"label":_vm.config.main_default_seo_template_id.field.label,"value":_vm.config.main_default_seo_template_id.field.value},on:{"selected":function($event){_vm.config.main_default_seo_template_id.field.value = $event}}}):_vm._e(),_c('DividerComponent',{attrs:{"size":"16"}}),(_vm.config.vacancy_default_seo_template_id)?_c('SelectDropdown',{attrs:{"options":_vm.config.templates,"label":_vm.config.vacancy_default_seo_template_id.field.label,"value":_vm.config.vacancy_default_seo_template_id.field.value},on:{"selected":function($event){_vm.config.vacancy_default_seo_template_id.field.value = $event}}}):_vm._e(),_c('DividerComponent',{attrs:{"size":"16"}}),(_vm.config.vacancies_default_seo_template_id)?_c('SelectDropdown',{attrs:{"options":_vm.config.templates,"label":_vm.config.vacancies_default_seo_template_id.field.label,"value":_vm.config.vacancies_default_seo_template_id.field.value},on:{"selected":function($event){_vm.config.vacancies_default_seo_template_id.field.value = $event}}}):_vm._e(),_c('DividerComponent'),_c('button',{staticClass:"default-hover",on:{"click":function($event){return _vm.save({
      vacancy_default_seo_template_id: _vm.config.vacancy_default_seo_template_id.field.value !== null &&
              typeof _vm.config.vacancy_default_seo_template_id.field.value ===
                'object'
                ? _vm.config.vacancy_default_seo_template_id.field.value.id
                : _vm.config.vacancy_default_seo_template_id.field.value,

      main_default_seo_template_id: _vm.config.main_default_seo_template_id.field.value !== null &&
              typeof _vm.config.main_default_seo_template_id.field.value ===
                'object'
                ? _vm.config.main_default_seo_template_id.field.value.id
                : _vm.config.main_default_seo_template_id.field.value,
      vacancies_default_seo_template_id: _vm.config.vacancies_default_seo_template_id.field.value !== null &&
              typeof _vm.config.vacancies_default_seo_template_id.field.value ===
                'object'
                ? _vm.config.vacancies_default_seo_template_id.field.value.id
                : _vm.config.vacancies_default_seo_template_id.field.value,
  })}}},[_vm._v("Сохранить")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }